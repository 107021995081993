import React, { FC, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'
import { Duration } from 'moment'

import { CheckIcon, LockIcon, UnLockIcon } from '../../../Icons'
import { Tooltip } from '../../../Tooltip'
import { Typography } from '../../../Typography'
import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'
import { Tag } from '../../../tags'
import { getTimeFromSeconds, splitNumberIntoThousands } from '../../../utils'

import style from './style.m.less'

export interface ICount {
    value: number
    unit?: string
}

export const Count: React.FC<ICount> = ({ value, unit }) => {
    return (
        <span>
            {splitNumberIntoThousands(value)}
            {unit && ` ${unit}`}
        </span>
    )
}

export const LinkCell = (
    value: ReactNode,
    to: string | undefined,
    state?: Record<string, unknown>,
    icon?: ReactElement,
) => {
    const stopPropClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation()
    }
    if (icon) {
        return (
            <Link onClick={stopPropClick} className={style.linkCell} to={to || '/'} state={state}>
                <span className={style.cellWithIcon}>
                    <span className={style.iconContainer}>{icon}</span>
                    {value}
                </span>
            </Link>
        )
    }
    return (
        <Link onClick={stopPropClick} className={style.linkCell} to={to || '/'} state={state}>
            {value}
        </Link>
    )
}

export const SpanWithIcon: FC<{ icon: ReactElement }> = ({ icon, children }) => {
    return (
        <div className={style.cellWithIcon}>
            <div className={style.iconContainer}>{icon}</div>
            {children}
        </div>
    )
}

export const CountCell = (value: string) => {
    return <Count value={+value} />
}

export const CurrencyCell = (value: string) => {
    return <Count value={+value} />
}

export const CurrencyCellWithCents = (value: string) => {
    return <Count value={+value / 100 ?? 0} />
}

export const TagCell = (value: string) => {
    return <Tag>{value}</Tag>
}

export const TitleCell = (value: string) => {
    return <Typography.Text strong>{value}</Typography.Text>
}

export const PercentCell = (value: number) => `${value ? (value * 100).toFixed(2) : '---'} %`

const getDateFromTimestamp = (timestamp: number) =>
    timestamp ? dayjs(timestamp * 1000).format('DD.MM.YYYY') : '---'

export const DateCell = (value: number) => getDateFromTimestamp(value)
export const DateRangeCell = (value: [number, number]) =>
    `${getDateFromTimestamp(value[0])} - ${getDateFromTimestamp(value[1])}`

export const TimeDeltaCell = (value: Duration) => {
    if (value.asDays() > 1) {
        return ''
    }
    return `${value.hours().toString().padStart(2, '0')}:${value
        .minutes()
        .toString()
        .padStart(2, '0')}:${value.seconds().toString().padStart(2, '0')}`
}

export const DateTimeCell = (value: number | string | undefined | null) => {
    if (value !== undefined && value !== null) {
        const date = typeof value === 'number' ? dayjs.unix(value) : dayjs(value)

        return (
            <>
                {date.format(VIEW_DATE_FORMAT)}
                <span className={style.secondaryText}>&nbsp;{date.format('HH:mm:ss')}</span>
            </>
        )
    }
    return EMPTY_FIELD_VALUE
}
export const RestDateCell =
    (isUnix = true, rest = 0) =>
    (value: number) => {
        if (!value) {
            return
        }
        const date = isUnix ? dayjs.unix(value) : dayjs(value)
        const diffDay = date.diff(dayjs(), 'day')
        const diffSecond = date.diff(dayjs(), 'second')
        if (diffDay <= rest && diffSecond > 0) {
            return <span className={style.dangerText}>{getTimeFromSeconds(diffSecond)}</span>
        }
        return <span>{diffDay} дн.</span>
    }

export const DangerPercent = (percent: number, maxPercent = 80) => {
    if (typeof percent !== 'number') {
        return
    }
    return (
        <span className={percent > maxPercent ? style.dangerText : undefined}>
            {percent.toFixed(2)}%
        </span>
    )
}

export const TooltipCell = (title: string) => {
    if (!title) {
        return ''
    }
    return <Tooltip title={title}>{title}</Tooltip>
}

export const InjunctionTickerCell = (isInjunction: boolean, ticker: string) => {
    return <SpanWithIcon icon={isInjunction ? <LockIcon /> : <UnLockIcon />}>{ticker}</SpanWithIcon>
}

export const ConfirmCell = (textConfirm: string) => (isConfirm: boolean) => {
    if (isConfirm) {
        return (
            <div className={style.confirmCell}>
                <CheckIcon />
                <span>{textConfirm}</span>
            </div>
        )
    }

    return null
}
